import "@stimulus/polyfills";
import { Application } from "stimulus";
import { definitionsFromContext } from "stimulus/webpack-helpers";
import "./application.css";
import "../assets/css/base.css";
import "../assets/css/slick-theme.css";
import "../assets/css/slick.css";
import "../assets/sass/_fonts.scss";
import "../assets/sass/main.scss";
import "../assets/css/all-ie-only.css";
import "../assets/js/slick.js";
import "../components/home_page/home_page_controller";
import "../components/informations/informations_controller";
import "../components/carousel/carousel_controller";
import Rails from "rails-ujs";
import "jquery-mask-plugin";

const application = Application.start();
const context = require.context("../components/", true, /^\.\/.*\.js$/);

require.context("../assets/images/", true, /\.(gif|jpg|png|svg)$/i);
require.context("../assets/images/logos", true, /\.(gif|jpg|png|svg)$/i);
application.load(definitionsFromContext(context));

window.jQuery = $;
window.$ = $;

$.jMaskGlobals.watchDataMask = true;

$('.carousel-logos').slick({
  autoplay: true,
  autoplaySpeed: 5000,
  arrows: false,
  slidesToShow: 4,
  slidesToScroll: 1,
  dots: false,
  infinite: true,
  responsive: [
    {
      breakpoint: 750,
      settings: {
        slidesToShow: 1
      }
    },
    {
      breakpoint: 551,
      settings: {
        slidesToShow: 1
      }
    }
  ]
});

Rails.start();
